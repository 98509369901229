// src/components/Table.js
import React from "react";
import "./Table.css";

function Table({ orders }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Q</th>
          <th>Status</th>
          <th>Date & Time</th>
          <th>Symbol</th>
          <th>Price</th>
          <th>Transaction Type</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((item) => (
          <tr key={item.id}>
            <td>{item.qty}</td>
            <td>{item.ordSt}</td>
            <td>{item.ordDtTm}</td>
            <td>{item.trdSym}</td>
            <td>{item.avgPrc}</td>
            <td>{item.trnsTp}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
