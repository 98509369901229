// src/utils.js

export const getDayOfWeek = (date) => {
  if (!date || !(date instanceof Date)) return "Unknown";

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[date.getDay()];
};
