import React, { useEffect, useState } from "react";
import { getDayOfWeek } from "./Data/Data_App.js";
import Table from "./components/Table.js";
import Loader from "./components/Loader.js";
import "./App.css";

function App() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [time, setTime] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [sortCriteria, setSortCriteria] = useState("price");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/get_all_order`,
          { method: "POST", headers: { "Content-Type": "application/json" } }
        );
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        const data = await response.json();
        setOrders(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        console.error(error);
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleButtonClick = async () => {
    setIsButtonDisabled(true);
    const url =
      time.getDay() === 5
        ? `${process.env.REACT_APP_API_URL}/friday`
        : `${process.env.REACT_APP_API_URL}/run-python`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
    } catch (error) {
      console.error(error);
      setIsButtonDisabled(false);
    }
  };

  const isMarketOpen = () => {
    const marketOpen = new Date().setHours(9, 15);
    const marketClose = new Date().setHours(15, 30);
    return time.getDay() >= 1 &&
      time.getDay() <= 5 &&
      time >= marketOpen &&
      time <= marketClose
      ? "Market Open"
      : "Market Closed";
  };

  const filteredOrders =
    filter === "all"
      ? orders
      : orders.filter((order) => order.ordSt === filter);
  const sortedOrders = [...filteredOrders].sort((a, b) =>
    sortOrder === "asc"
      ? a[sortCriteria] - b[sortCriteria]
      : b[sortCriteria] - a[sortCriteria]
  );

  return (
    <div className="App">
      <header className="header">
        <h1>Time: {time.toLocaleTimeString()}</h1>
        <h2>Date: {time.toLocaleDateString()}</h2>
        <h3>Day: {getDayOfWeek(time)}</h3>
        <h4>Market: {isMarketOpen()}</h4>
        <button onClick={handleButtonClick} disabled={isButtonDisabled}>
          Run
        </button>
      </header>
      <main>
        <h1>Orders</h1>
        <div className="filter">
          <label>Filter:</label>
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All</option>
            <option value="complete">Complete</option>
            <option value="rejected">Rejected</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <div className="sort">
          <label>Sort by:</label>
          <select
            value={sortCriteria}
            onChange={(e) => setSortCriteria(e.target.value)}
          >
            <option value="price">Price</option>
            <option value="datetime">Date & Time</option>
          </select>
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
        {loading ? <Loader /> : <Table orders={sortedOrders} />}
      </main>
    </div>
  );
}

export default App;
